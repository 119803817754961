import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { css } from 'emotion'
import MQ from '../globals/mq'
import Responsive from 'react-responsive'
import { keyframes } from 'react-emotion'
import Img from 'gatsby-image'
import ReactPlayer from 'react-player'
import Layout from '../components/layout'
import Colors from '../globals/colors'
import GoodShepherd from '../assets/good-shepherd'
import SuperScarySnakes from '../assets/super-scary-snakes'
import Logo from '../images/black-future-88-rgba.png'
import LogoRed from '../images/black-future-88-red.png'
import LogoGreen from '../images/black-future-88-green.png'
import LogoBlue from '../images/black-future-88-blue.png'
import NoiseImage from '../images/noisy-texture-300x300-o18-d27-c-eb7f0c-t1.png'

// == variables
const animationTime = 1000
const scanlinesWidth = `4px`
const scanlinesTime = `0.2s`
const noiseTime = `1s`
const opacityTime = `3s`

// == qualifiers
const supportsMixBlendMode =
  typeof window !== 'undefined' &&
  window.getComputedStyle(document.body).mixBlendMode

const supportBackgroundBlendMode =
  typeof window !== 'undefined' &&
  window.getComputedStyle(document.body).backgroundBlendMode

// == animations
const rgbImageFrames = keyframes({
  '0%': { backgroundPosition: `0 0` },
  '10%': { backgroundPosition: `-5% -10%` },
  '20%': { backgroundPosition: `-15% 5%` },
  '30%': { backgroundPosition: `7% -25%` },
  '40%': { backgroundPosition: `20% 25%` },
  '50%': { backgroundPosition: `-25% 10%` },
  '60%': { backgroundPosition: `15% 5%` },
  '70%': { backgroundPosition: `0 15%` },
  '80%': { backgroundPosition: `25% 35%` },
  '90%': { backgroundPosition: `-10% 10%` },
  '100%': { backgroundPosition: `0 0` }
})

const opacityFrames = keyframes({
  '0%': { opacity: `0.6` },
  '20%': { opacity: `0.3` },
  '35%': { opacity: `0.5` },
  '50%': { opacity: `0.8` },
  '60%': { opacity: `0.4` },
  '80%': { opacity: `0.7` },
  '100%': { opacity: `0.6` }
})

const scanlinesFrames = keyframes({
  from: {
    backgroundImage: `linear-gradient(
      to bottom,
      transparent 50%,
      rgba(0,0,0,0.5) 51%
    )`,
    backgroundSize: `100% ${ scanlinesWidth }`,
  },
  to: {
    backgroundImage: `linear-gradient(
      to bottom,
      rgba(0,0,0,0.5) 50%,
      transparent 51%
    )`,
    backgroundSize: `100% ${ scanlinesWidth }`,
  }
})

const noiseFrames = keyframes({
  '0%': { backgroundPosition: `0 0` },
  '10%': { backgroundPosition: `-5% -10%` },
  '20%': { backgroundPosition: `-15% 5%` },
  '30%': { backgroundPosition: `7% -25%` },
  '40%': { backgroundPosition: `20% 25%` },
  '50%': { backgroundPosition: `-25% 10%` },
  '60%': { backgroundPosition: `15% 5%` },
  '70%': { backgroundPosition: `0 15%` },
  '80%': { backgroundPosition: `25% 35%` },
  '90%': { backgroundPosition: `-10% 10%` },
  '100%': { backgroundPosition: `0 0` }
})

const pinned = css({
  position: `fixed`,
  top: 0,
  left: 0,
  width: `100vw`,
  height: `100vh`,
  overflow: `hidden`,
  pointerEvents: `none`,
  '&:before': {
    content: `''`,
    position: `absolute`,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    pointerEvents: `none`,
  }
})

// == noise
const noise = css({
  zIndex: `400`,
  opacity: `1`,
  '&:before': {
    background: `url(${ NoiseImage })`,
  }
}, pinned)

const moving = css({
  zIndex: `450`,
  opacity: `1`,
  '&:before': {
    background: `url(${ NoiseImage })`,
    // willChange: `background-position`,
    animation: `${ noiseFrames } ${ noiseTime } infinite alternate`
  }
})

// == vhs effects
const scanlines = css({
  zIndex: `300`,
  opacity: `0.6`,
  // willChange: `opacity`,
  animation: `${ opacityFrames } ${ opacityTime } linear infinite`,
  '&:before': {
    backgroundImage: `linear-gradient(
      to bottom,
      transparent 50%,
      rgba(0,0,0,0.5) 51%
    )`,
    backgroundSize: `100% ${ scanlinesWidth }`,
    // willChange: `backgroundImage, backgroundSize`,
    animation: `${ scanlinesFrames } ${ scanlinesTime } linear infinite`
  }
}, pinned)

// == content
const feature = css({
  position: `absolute`,
  display: `block`,
  width: `100%`,
  textAlign: `center`,
  opacity: 0,
  transform: `perspective(100px) translateZ(10px)`,
  filter: `blur(10px)`,
  letterSpacing: `0.1rem`,
  fontVariationSettings: `'wght' 700, 'ital' 1, 'wdth' 70`,
  fontSynthesis: `none`,
  textShadow: `
    0 0 5px ${ Colors.white },
    0 0 20px ${ Colors.magenta },
    0 0 40px ${ Colors.magenta }, 0 0 60px ${ Colors.magenta }`,
  '&.active': {
    opacity: 1,
    transform: `perspective(100px) translateZ(0px)`,
    filter: `blur(0px)`,
    letterSpacing: `0.15rem`,
    transition: `
      opacity ${ animationTime }ms linear,
      transform ${ animationTime }ms linear,
      filter ${ animationTime * 0.4 }ms linear,
      letter-spacing ${ animationTime }ms linear`,
  },
  '&.past': {
    opacity: 0,
    transform: `perspective(100px) translateZ(-10px)`,
    filter: `blur(10px)`,
    letterSpacing: `0.2rem`,
    transition: `
      opacity ${ animationTime }ms linear,
      transform ${ animationTime }ms linear,
      filter ${ animationTime * 0.4 }ms linear,
      letter-spacing ${ animationTime }ms linear`
  }
})

const about = css({
  fontSize: `1.6rem`,
  fontVariationSettings: `'wght' 500, 'ital' 0, 'wdth' 70`,
  margin: `0 auto 2rem`,
  width: `60%`,
  em: {
    fontStyle: `normal`,
    display: `inline`,
    backgroundColor: `rgba(25, 0, 42, 0.72)`,
    padding: `0.4rem 0.6rem`,
    lineHeight: `1.9`
  }
})

const timer = css({
  fontSize: `4rem`,
  color: Colors.white,
  fontVariationSettings: `'wght' 700, 'ital' 0, 'wdth' 70`,
  fontSynthesis: `none`,
})

const buy = css({
  fontSize: `3rem`
})

const content = css({
  position: `relative`,
  width: `100vw`,
  textAlign: `center`,
  fontSize: `2rem`,
  textTransform: `uppercase`,
  fontFamily: `Kairos`,
  color: Colors.white,
  span: [feature]
})

// == header
const navigation = css({
  position: `relative`,
  width: `100%`,
  display: `flex`,
  alignSelf: `flex-start`,
  alignItems: `center`,
  justifyContent: `space-between`,
  padding: `2rem 3rem 0`,
  color: Colors.white,
  fontWeight: `normal`,
  letterSpacing: `1px`,
  textTransform: `uppercase`
})
const navItem = css({
  display: `inline-block`,
  fontSize: `0.9rem`,
  padding: `0 1.8rem`,
  color: Colors.white,
  letterSpacing: `1px`,
  '&:first-child': {
    paddingLeft: 0
  }
})
const link = css({
  cursor: `pointer`,
  transition: `color 0.3s ease`,
  '&:hover': {
    color: Colors.purple,
  }
})
const nav = css({
  display: `flex`,
  alignItems: `center`,
  marginBottom: `2rem`,
  a: [navItem, link],
  span: navItem
})
const close = css({
  color: `${ Colors.blue } !important`,
  '&:hover': {
    color: `${ Colors.gold } !important`
  }
})
const when = css({
  fontSize: `1.2rem !important`,
  color: `${ Colors.gold } !important`
})

// == footer
const footer = css({
  display: `flex`,
  flexDirection: `column`,
  width: `58%`,
  margin: `0 auto`,
  paddingBottom: `3rem`
})
const team = css({
  position: `relative`,
  width: `100%`,
  display: `flex`,
  justifyContent: `center`,
  padding: `0 3rem 2rem`,
  color: Colors.white
})
const logo = css({
  cursor: `pointer`,
  transition: `color 0.3s ease`,
  '&:hover': {
    color: Colors.magenta
  }
})
const logos = css({
  display: `flex`,
  alignItems: `center`,
  a: [navItem, logo]
})

// == logos
const staticLogo = css({
  backgroundSize: `cover`,
  backgroundImage: `url(${ Logo })`,
  backgroundPosition: `center`
})
const rgbLogo = css({
  position: `relative`,
  overflow: `hidden`,
  backgroundImage: `url(${ LogoBlue })`,
  backgroundBlendMode: `lighten`,
  backgroundSize: `99%`,
  backgroundPosition: `center`,
  animation: `${ rgbImageFrames } 1s steps(11) 0s infinite normal`,
  '&:before, &:after': {
    display: `none`,
    content: `''`,
    position: `absolute`,
    top: `0`,
    right: `0`,
    bottom: `0`,
    left: `0`,
    pointerEvents: `none`
  },
  '&:before': {
    display: `block`,
    backgroundImage: `url(${ LogoRed })`,
    backgroundBlendMode: `lighten`,
    backgroundSize: `99%`,
    backgroundPosition: `center`,
    animation: `${ rgbImageFrames } 1s steps(11) 0s infinite reverse`
  },
  '&:after': {
    display: `block`,
    backgroundImage: `url(${ LogoGreen })`,
    mixBlendMode: `screen`,
    backgroundSize: `99%`,
    backgroundPosition: `center`
  }
})

const sss = css({
  width: `16.625rem`,
  height: `1.9rem`
})

const gse = css({
  width: `10.75rem`,
  height: `2.875rem`
})

// const trailerUrl = `https://youtube.com/watch?v=EHj1e8ZqZMM`
const switchUrl = `https://youtube.com/watch?v=UgbHBPIxX80`
const explainerUrl = `https://youtube.com/watch?v=w5MjT9VfMKU`

// == responsive
const Landscape = props => <Responsive {...props} orientation={'landscape'} />
const Portrait = props => <Responsive {...props} orientation={'portrait'} />

class IndexPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      playing: false,
      showing: true,
      url: null
    }
    // trailer
    this.onTrailerClickHandler = this.onTrailerClickHandler.bind(this)
    this.onStopClickHandler = this.onStopClickHandler.bind(this)
    this.onTrailerEndedHandler = this.onTrailerEndedHandler.bind(this)
    this.ref = this.ref.bind(this)
  }
  componentDidMount () {
    this.animate()
  }
  componentWillUnmount () {
    clearInterval(this.timer)
  }
  hasClass (el, className) {
    if (el.classList) {
      return el.classList.contains(className)
    } else {
      return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
    }
  }
  addClass (el, className) {
    if (el.classList) {
      el.classList.add(className)
    } else if (!this.hasClass(el, className)) {
      el.className += ' ' + className
    }
  }
  removeClass (el, className) {
    if (el.classList) {
      el.classList.remove(className)
    } else if (this.hasClass(el, className)) {
      let reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
      el.className = el.className.replace(reg, ' ')
    }
  }
  swapClasses (el, classNameToAdd, classNameToRemove) {
    this.addClass(el, classNameToAdd)
    this.removeClass(el, classNameToRemove)
  }
  pairwise (arr, func) {
    for (let i = 0; i < arr.length - 1; i++) {
      func(arr[i], arr[i + 1])
    }
  }
  nextItem (arr, index) {
    let i = index + 1
    i = index % arr.length
    return arr[i]
  }
  animate () {
    let spans = this.refs.content.getElementsByTagName('span')
    let active = this.refs.content.getElementsByClassName('active')
    let i = 0
    let j = 1
    if (active.length === 0) {
      spans[0].classList.add('active')
    }
    this.timer = setInterval(
      () => {
        for (let i = 0; i < spans.length; i++) {
          spans[i].classList.remove('active')
        }
        for (let i = 0; i < spans.length; i++) {
          spans[i].classList.remove('past')
        }
        spans[j++ % spans.length].classList.add('active')
        spans[i++ % spans.length].classList.add('past')
      }, 3600
    )
  }
  onTrailerClickHandler (video) {
    this.setState({ url: video, playing: true, showing: false })
  }
  onStopClickHandler () {
    this.player.seekTo(0)
    this.setState({ playing: false, showing: true })
  }
  onTrailerEndedHandler () {
    this.setState({ playing: false, showing: true })
  }
  ref (player) {
    this.player = player
  }
  render () {
    const {
      playing,
      showing,
      url
    } = this.state
    return (
      <Layout>
        <div
          style={{
            width: `100%`,
            height: `100%`,
            position: `absolute`
          }}
        >

          <div className={ scanlines }></div>
          <div className={ noise }></div>
          <div className={`${ noise } ${ moving }`}></div>

          <Portrait>
            <Img
              backgroundColor={`${ Colors.purple }`}
              style={{
                width: `100%`,
                height: `100%`,
                position: `absolute`
              }}
              fluid={
                this.props.data.keyartPortraitImage.childImageSharp.fluid
              }
            />
          </Portrait>
          <Landscape>
            <Img
              backgroundColor={`${ Colors.purple }`}
              style={{
                width: `100%`,
                height: `100%`,
                position: `absolute`
              }}
              fluid={
                this.props.data.keyartLandscapeImage.childImageSharp.fluid
              }
            />
          </Landscape>
        </div>
        <div
          style={{
            position: `absolute`,
            width: `100%`,
            height: `100%`,
            backgroundColor: `${ Colors.purple }`,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignCenter: `center`,
            opacity: playing ? `1` : `0`
          }}
        >
          <ReactPlayer
            ref={this.ref}
            className={css({
              position: `absolute`,
              top: 0,
              left: 0
            })}
            url={url}
            width="100%"
            height="100%"
            volume={0.4}
            playing={playing}
            onEnded={this.onTrailerEndedHandler}
            config={{
              youtube: {
                playerVars: {
                  controls: 0,
                  modestbranding: 1,
                  fs: 1
                }
              }
            }}
          />
        </div>
        <nav
          className={css`
            ${ navigation }
            & {
              @media only screen and (orientation: portrait) {
                flex-direction: column;
                justify-content: center;
                text-align: center;
              }
              ${ MQ.ipSE(css`
                  flex-wrap: wrap;
                  flex-direction: row;
                `) }
              ${ MQ.ip68(css`
                  flex-wrap: wrap;
                `) }
              ${ MQ.ip8U(css`
                  flex-wrap: wrap;
                `) }
              ${ MQ.ipXX(css`
                  flex-wrap: wrap;
                `) }
            }
          `}
        >
          <div className={nav}>
            <Landscape>
              <div
                className={css(
                  {
                    width: `22.1145rem`,
                    height: `3.54rem`,
                    marginRight: `1.4rem`
                  },
                  supportsMixBlendMode !== 'undefined' &&
                    supportBackgroundBlendMode !== 'undefined'
                    ? rgbLogo
                    : staticLogo
                )}
              />
            </Landscape>
            <span className={when}>COMING SOON</span>
          </div>
          <div className={nav}>
            <span
              onClick={this.onStopClickHandler}
              style={{ display: playing ? `block` : `none` }}
              className={`${ navItem } ${ link } ${ close }`}
            >
              Stop Video
            </span>
            <span
              onClick={() => this.onTrailerClickHandler(switchUrl)}
              style={{ display: playing ? `none` : `block` }}
              className={`${ navItem } ${ link }`}
            >
              Watch Trailer
            </span>
            <span
              onClick={() => this.onTrailerClickHandler(explainerUrl)}
              style={{ display: playing ? `none` : `block` }}
              className={`${ navItem } ${ link }`}
            >
              Watch Latest Q&amp;A
            </span>
            <span
              data-xpaystation-widget-open
              className={`${ navItem } ${ link }`}
            >
              Buy Beta
            </span>
            <a href="https://discordapp.com/invite/goodshepherdgames">
              Join the Community
            </a>
          </div>
        </nav>
        <div
          style={{ display: showing ? `block` : `none` }}
          className={ content } ref='content'>
          <div
            style={{ display: `none` }}
            className={ timer }>
            00:00:00
            <strong className={buy}>BUY BETA</strong></div>
          <p className={ about }>
            <em>
              Black Future ’88 is a Synth-Punk roguelike 2D action Shooter
            </em>
            <br />
            <em>set in a stylish alternative version of 1988</em>
          </p>
          <span>climb an evolving tower</span>
          <span>kill the wardens</span>
          <span>ruin friendships with couch co-op</span>
          <span>unlock over 50 weapons and buffs</span>
          <span>before your heart explodes</span>
        </div>
        <div>
          <div
            className={css`
              ${ footer }
              & {
                @media only screen and (orientation: portrait) {
                  width: 100% !important;
                }
                ${ MQ.ipSE(css`
                    width: 64%;
                    padding: 0 2rem 1rem;
                  `) }
                ${ MQ.ip68(css`
                    padding: 0 3rem 3rem;
                  `) }
                ${ MQ.ip8U(css`
                    padding: 0 3rem 3rem;
                  `) }
                ${ MQ.ipXX(css`
                    padding: 0 3rem 3rem;
                  `) }
              }
              opacity: ${ playing ? 0 : 1 };
            `}
          >
            <Portrait>
              <div
                className={css`
                  width: 100%;
                  display: flex;
                  align-items: flex-end;
                  justify-content: center;
                `}
              >
                <div
                  className={css`
                    ${ MQ.sm(css`
                        width: 60vw;
                        height: 9vh;
                      `) }
                    ${ MQ.ipSE(css`
                        width: 90vw;
                        height: 8.5vh;
                      `) }
                    ${ MQ.ip68(css`
                        width: 90vw;
                        height: 8vh;
                      `) }
                    ${ MQ.ip8U(css`
                        width: 90vw;
                        height: 7vh;
                      `) }
                    ${ MQ.ipXX(css`
                        width: 90vw;
                        height: 7vh;
                      `) }
                    ${ supportsMixBlendMode !== 'undefined' &&
                        supportBackgroundBlendMode !== 'undefined' ? rgbLogo
        : staticLogo } `}
                />
              </div>
            </Portrait>
          </div>
          <nav className={team}>
            <div className={logos}>
              <a href="https://twitter.com/superscarysnake">
                <SuperScarySnakes className={sss} />
              </a>
              <a href="https://twitter.com/goodshepherdent">
                <GoodShepherd className={gse} />
              </a>
            </div>
          </nav>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query {
    keyartLandscapeImage: file(relativePath: { regex: "/landscape/" }) {
      childImageSharp {
        fluid(maxWidth: 5120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    keyartPortraitImage: file(relativePath: { regex: "/portrait/" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logoImage: file(relativePath: { regex: "/rgba/" }) {
      childImageSharp {
        fluid(maxWidth: 1930) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
